// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* frontend/src/components/Footer/Footer.module.css */

.Footer_footer__svwtw {
    background-color: var(--color-background-secondary-black);
    color: var(--color-text-white);
    padding: var(--padding-medium);
    text-align: center;
    width: 100%;
    position: relative;
    left: 0;
    bottom: 0;
}

.Footer_container__JxcQj {
    max-width: var(--max-width-container);
    margin: 0 auto;
}

.Footer_text__TBSxE {
    margin: var(--margin-small) 0;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none;
}

.Footer_socialLinks__8P9F4 {
    margin-top: var(--margin-medium);
    font-size: var(--font-size-large);
    gap: var(--spacing-small);
    display: flex;
    justify-content: center;
}

.Footer_socialLinks__8P9F4 a {
    margin: 0 var(--margin-small);
}

.Footer_instagramfooter__nxioq,
.Footer_facebookfooter__LsLcH,
.Footer_youtubefooter__L0PFW {
    color: var(--color-text-white);
    transition: color var(--transition-default);
}

.Footer_instagramfooter__nxioq:hover {
    color: var(--color-instagram);
}

.Footer_facebookfooter__LsLcH:hover {
    color: var(--color-facebook);
}

.Footer_youtubefooter__L0PFW:hover {
    color: var(--color-youtube);
}`, "",{"version":3,"sources":["webpack://./src/components/Site/Footer/Footer.module.css"],"names":[],"mappings":"AAAA,qDAAqD;;AAErD;IACI,yDAAyD;IACzD,8BAA8B;IAC9B,8BAA8B;IAC9B,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,SAAS;AACb;;AAEA;IACI,qCAAqC;IACrC,cAAc;AAClB;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,gCAAgC;IAChC,iCAAiC;IACjC,yBAAyB;IACzB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;;;IAGI,8BAA8B;IAC9B,2CAA2C;AAC/C;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":["/* frontend/src/components/Footer/Footer.module.css */\r\n\r\n.footer {\r\n    background-color: var(--color-background-secondary-black);\r\n    color: var(--color-text-white);\r\n    padding: var(--padding-medium);\r\n    text-align: center;\r\n    width: 100%;\r\n    position: relative;\r\n    left: 0;\r\n    bottom: 0;\r\n}\r\n\r\n.container {\r\n    max-width: var(--max-width-container);\r\n    margin: 0 auto;\r\n}\r\n\r\n.text {\r\n    margin: var(--margin-small) 0;\r\n    font-size: 16px;\r\n    color: #ffffff;\r\n    text-decoration: none;\r\n}\r\n\r\n.socialLinks {\r\n    margin-top: var(--margin-medium);\r\n    font-size: var(--font-size-large);\r\n    gap: var(--spacing-small);\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.socialLinks a {\r\n    margin: 0 var(--margin-small);\r\n}\r\n\r\n.instagramfooter,\r\n.facebookfooter,\r\n.youtubefooter {\r\n    color: var(--color-text-white);\r\n    transition: color var(--transition-default);\r\n}\r\n\r\n.instagramfooter:hover {\r\n    color: var(--color-instagram);\r\n}\r\n\r\n.facebookfooter:hover {\r\n    color: var(--color-facebook);\r\n}\r\n\r\n.youtubefooter:hover {\r\n    color: var(--color-youtube);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__svwtw`,
	"container": `Footer_container__JxcQj`,
	"text": `Footer_text__TBSxE`,
	"socialLinks": `Footer_socialLinks__8P9F4`,
	"instagramfooter": `Footer_instagramfooter__nxioq`,
	"facebookfooter": `Footer_facebookfooter__LsLcH`,
	"youtubefooter": `Footer_youtubefooter__L0PFW`
};
export default ___CSS_LOADER_EXPORT___;
