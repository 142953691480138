// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Site/SobreNos/SobreNos.module.css */

.SobreNos_beliefsSection__mjeXC {
    margin-top: 100px;
    margin-bottom: 100px;
    text-align: center;
}

.SobreNos_beliefsContainer__PvZYu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto;
}

.SobreNos_beliefsItem__ZfqXY {
    background-color: #ffffff;
    flex: 1 1 30%;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.192);
    transition: transform 0.3s, box-shadow 0.3s;
}

.SobreNos_beliefsItem__ZfqXY:hover {
    transform: translateY(-10px);
    box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.397);
}

.SobreNos_beliefsItem__ZfqXY h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
}

.SobreNos_beliefsItem__ZfqXY p {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5;
}

.SobreNos_beliefsItem__ZfqXY a {
    text-decoration: none;
    color: #e6392f;
    font-weight: bold;
    font-size: 1em;
}

.SobreNos_beliefsItem__ZfqXY a span {
    transition: transform 0.3s;
}

.SobreNos_beliefsItem__ZfqXY a:hover span {
    transform: translateX(5px);
}`, "",{"version":3,"sources":["webpack://./src/pages/Site/SobreNos/SobreNos.module.css"],"names":[],"mappings":"AAAA,gDAAgD;;AAEhD;IACI,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,yCAAyC;IACzC,2CAA2C;AAC/C;;AAEA;IACI,4BAA4B;IAC5B,gDAAgD;AACpD;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,WAAW;IACX,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":["/* src/pages/Site/SobreNos/SobreNos.module.css */\r\n\r\n.beliefsSection {\r\n    margin-top: 100px;\r\n    margin-bottom: 100px;\r\n    text-align: center;\r\n}\r\n\r\n.beliefsContainer {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: space-around;\r\n    max-width: 1200px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.beliefsItem {\r\n    background-color: #ffffff;\r\n    flex: 1 1 30%;\r\n    margin: 10px;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.192);\r\n    transition: transform 0.3s, box-shadow 0.3s;\r\n}\r\n\r\n.beliefsItem:hover {\r\n    transform: translateY(-10px);\r\n    box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.397);\r\n}\r\n\r\n.beliefsItem h3 {\r\n    font-size: 1.5em;\r\n    margin-bottom: 20px;\r\n    color: #333;\r\n}\r\n\r\n.beliefsItem p {\r\n    font-size: 1em;\r\n    color: #666;\r\n    margin-bottom: 20px;\r\n    line-height: 1.5;\r\n}\r\n\r\n.beliefsItem a {\r\n    text-decoration: none;\r\n    color: #e6392f;\r\n    font-weight: bold;\r\n    font-size: 1em;\r\n}\r\n\r\n.beliefsItem a span {\r\n    transition: transform 0.3s;\r\n}\r\n\r\n.beliefsItem a:hover span {\r\n    transform: translateX(5px);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"beliefsSection": `SobreNos_beliefsSection__mjeXC`,
	"beliefsContainer": `SobreNos_beliefsContainer__PvZYu`,
	"beliefsItem": `SobreNos_beliefsItem__ZfqXY`
};
export default ___CSS_LOADER_EXPORT___;
