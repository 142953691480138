// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* frontend/src/components/Banner/Banner.module.css */

.Banner_banner__7EnNA {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    margin-top: var(--margin-banner);
    width: 100%;
}

.Banner_media__1omAx {
    height: auto;
    width: 100%;
    object-fit: cover;
    position: relative;
}

.Banner_sloganContainer__Tjr9u {
    position: absolute;
    text-align: center;
    width: 100%;
}

.Banner_slogan__NJp1E {
    color: var(--color-text-white);
    text-shadow: 2px 2px var(--color-text-black);
}

.Banner_subSlogan__uvGhw {
    color: var(--color-text-white);
    text-shadow: 1px 1px var(--color-text-black);
    margin-top: var(--margin-small);
}

.Banner_banner__7EnNA h1 {
    font-size: var(--font-size-h1-banner);
    font-weight: bold;
}

.Banner_banner__7EnNA h2 {
    font-size: var(--font-size-h2-banner);
    font-weight: bold;
}

.Banner_banner__7EnNA h3 {
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: var(--font-size-h3-banner);
}`, "",{"version":3,"sources":["webpack://./src/components/Site/Banner/Banner.module.css"],"names":[],"mappings":"AAAA,qDAAqD;;AAErD;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,gCAAgC;IAChC,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,8BAA8B;IAC9B,4CAA4C;AAChD;;AAEA;IACI,8BAA8B;IAC9B,4CAA4C;IAC5C,+BAA+B;AACnC;;AAEA;IACI,qCAAqC;IACrC,iBAAiB;AACrB;;AAEA;IACI,qCAAqC;IACrC,iBAAiB;AACrB;;AAEA;IACI,wEAAwE;IACxE,qCAAqC;AACzC","sourcesContent":["/* frontend/src/components/Banner/Banner.module.css */\r\n\r\n.banner {\r\n    position: relative;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: center;\r\n    height: auto;\r\n    margin-top: var(--margin-banner);\r\n    width: 100%;\r\n}\r\n\r\n.media {\r\n    height: auto;\r\n    width: 100%;\r\n    object-fit: cover;\r\n    position: relative;\r\n}\r\n\r\n.sloganContainer {\r\n    position: absolute;\r\n    text-align: center;\r\n    width: 100%;\r\n}\r\n\r\n.slogan {\r\n    color: var(--color-text-white);\r\n    text-shadow: 2px 2px var(--color-text-black);\r\n}\r\n\r\n.subSlogan {\r\n    color: var(--color-text-white);\r\n    text-shadow: 1px 1px var(--color-text-black);\r\n    margin-top: var(--margin-small);\r\n}\r\n\r\n.banner h1 {\r\n    font-size: var(--font-size-h1-banner);\r\n    font-weight: bold;\r\n}\r\n\r\n.banner h2 {\r\n    font-size: var(--font-size-h2-banner);\r\n    font-weight: bold;\r\n}\r\n\r\n.banner h3 {\r\n    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;\r\n    font-size: var(--font-size-h3-banner);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `Banner_banner__7EnNA`,
	"media": `Banner_media__1omAx`,
	"sloganContainer": `Banner_sloganContainer__Tjr9u`,
	"slogan": `Banner_slogan__NJp1E`,
	"subSlogan": `Banner_subSlogan__uvGhw`
};
export default ___CSS_LOADER_EXPORT___;
