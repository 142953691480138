/* src/pages/Site/Eventos/Eventos.js */
import React from 'react';

import Menu from '../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../components/Site/Footer/Footer';
import CultosEventos from '../../../components/Site/CultosEventos/CultosEventos';

import styles from './Eventos.module.css';

const Eventos = () => {
    return (
        <>
            <Menu />
            <main>
                <div className={styles.calendarContainer}>
                    <iframe
                        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&ctz=Europe%2FLondon&bgcolor=%23ffffff&showTitle=0&showPrint=0&showCalendars=0&showTz=0&hl=pt_PT&src=Z2VyYWxAbGlmdGFkLnB0&color=%23039BE5"
                        width="800"
                        height="600"
                        title="Calendário de Eventos"
                    ></iframe>
                </div>
                <CultosEventos />
            </main>
            <Footer />
        </>
    );
};

export default Eventos;
