/* src/pages/Site/SobreNos/SobreNos.js */
import React from 'react';
import { Link } from 'react-router-dom';

import Menu from '../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../components/Site/Footer/Footer';
import Banner from '../../../components/Site/Banner/Banner';

import styles from './SobreNos.module.css';

const SobreNos = () => {
    return (
        <>
            <Menu />
            <Banner
                mediaSrc="/assets/sobre-nos-banner.png"
                isVideo={false}
                sloganText="Sobre nós"
                sloganTag="h1"
                subSloganText="Conhece mais sobre a igreja LIFT"
                subSloganTag="h3"
            />
            <section className={styles.beliefsSection}>
                <div className={styles.beliefsContainer}>
                    <div className={styles.beliefsItem}>
                        <Link to="/sobre-nos/declaracao">
                            <h3>No que Acreditamos</h3>
                            <p>
                                Vê como vemos Deus, Jesus, a Bíblia, o homem e muitos aspetos
                                significativos da nossa fé.
                            </p>
                            Mais detalhes <span>→</span>
                        </Link>
                    </div>
                    <div className={styles.beliefsItem}>
                        <Link to="/sobre-nos/historia">
                            <h3>A nossa história</h3>
                            <p>Conhece mais sobre a história da igreja LIFT</p>
                            Mais detalhes <span>→</span>
                        </Link>
                    </div>
                    <div className={styles.beliefsItem}>
                        <Link to="/sobre-nos/lideranca">
                            <h3>Liderança</h3>
                            <p>Conhece os nossos pastores e a nossa liderança.</p>
                            Mais detalhes <span>→</span>
                        </Link>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default SobreNos;
