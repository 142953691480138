import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Hamburguer.module.css';

const Hamburguer = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
            if (window.innerWidth > 768) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className={styles.header}>
            <div className={styles.container}>
                <Link to="/">
                    <img src="/assets/Logos/logo-igreja.png" alt="Logo da Igreja" className={styles.logo} />
                </Link>
                <nav className={styles.nav}>
                    {isMobileView && (
                        <div className={styles.hamburger} onClick={toggleMenu}>
                            <input type="checkbox" checked={isMenuOpen} readOnly />
                            <svg fill="none" viewBox="0 0 50 50" height="50" width="50">
                                <path
                                    className={`${styles.lineTop} ${styles.line}`}
                                    stroke-linecap="round"
                                    stroke-width="4"
                                    stroke="black"
                                    d="M6 11L44 11"
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="4"
                                    stroke="black"
                                    d="M6 24H43"
                                    className={`${styles.lineMid} ${styles.line}`}
                                ></path>
                                <path
                                    stroke-linecap="round"
                                    stroke-width="4"
                                    stroke="black"
                                    d="M6 37H43"
                                    className={`${styles.lineBottom} ${styles.line}`}
                                ></path>
                            </svg>
                        </div>
                    )}
                    <ul className={isMobileView ? (isMenuOpen ? styles.showMenu : '') : styles.showMenu}>
                        <button className={styles.nav_bar_button}>
                            <Link to="/">Home</Link>
                        </button>
                        <button className={styles.nav_bar_button}>
                            <Link to="/sobre-nos">Sobre Nós</Link>
                        </button>
                        <button className={styles.nav_bar_button}>
                            <Link to="/ministerios">Ministérios</Link>
                        </button>
                        <button className={styles.nav_bar_button}>
                            <Link to="/eventos">Calendário</Link>
                        </button>
                        <button className={styles.nav_bar_button}>
                            <Link to="/contactos">Contactos e Localizações</Link>
                        </button>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Hamburguer;
