/* src/pages/Site/HomePage/HomePage.js */
import React from 'react';
import { Link } from 'react-router-dom';

import Menu from '../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../components/Site/Footer/Footer';
import Banner from '../../../components/Site/Banner/Banner';
import CultosEventos from '../../../components/Site/CultosEventos/CultosEventos';

import styles from './HomePage.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomePage = () => {
    return (
        <>
            <Menu />
            <div className={styles.mainContainer}>
                <Banner
                    mediaSrc="/assets/homescreen_video.MP4"
                    isVideo={true}
                    sloganText="Conhecer Deus, conhecer a Vida"
                    sloganTag="h2"
                />
                <section className={styles.sobreNos}>
                    <div>
                        <h2>Bem-vindo!</h2>
                        <p>
                            Que bom que vieste. Alegramo-nos com a tua presença e sabemos que
                            Deus também está feliz por estares aqui. Seja Bem-vindo(a)!
                        </p>
                        <h6>
                            “Todos vocês que temem a Deus, venham e escutem, e eu contarei o
                            que Ele tem feito por mim.” - Salmos 66:16 (NTLH)
                        </h6>
                    </div>
                </section>

                <section className={styles.ministerios}>
                    <div className={styles.ministeriosContent}>
                        <div>
                            <h1>Os Nossos Ministérios</h1>
                            <p>
                                Da nossa família para a tua. <br />
                                Descobre mais como te podes envolver na nossa igreja.
                            </p>
                            <button className={styles.buttonMinisterios}>
                                <Link to="/ministerios">Mais Detalhes</Link>
                            </button>
                        </div>
                    </div>
                </section>
                <CultosEventos />
            </div>

            <Footer />
        </>
    );
};

export default HomePage;
