// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/Site/Eventos/Eventos.module.css */
.Eventos_calendarContainer__nSsu9 {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Site/Eventos/Eventos.module.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":["/* src/pages/Site/Eventos/Eventos.module.css */\r\n.calendarContainer {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-bottom: 2rem;\r\n    margin-top: 50px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendarContainer": `Eventos_calendarContainer__nSsu9`
};
export default ___CSS_LOADER_EXPORT___;
