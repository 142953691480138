import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import axios from 'axios';

import HomePage from "./pages/Site/HomePage/HomePage";
import SobreNos from "./pages/Site/SobreNos/SobreNos";
import Ministerios from "./pages/Site/Ministerios/Ministerios";
import Eventos from "./pages/Site/Eventos/Eventos";
import Contactos from "./pages/Site/Contactos/Contactos";
import Declaracao from "./pages/Site/SobreNos/Declaracao/Declaracao";
import Historia from "./pages/Site/SobreNos/Historia/Historia";
import Lideranca from "./pages/Site/SobreNos/Lideranca/Lideranca";

import Login from "./pages/LIFTrac/Login/Login";
import CrenteList from "./pages/LIFTrac/crentes/Crentes";
import HomeScreen from './pages/LIFTrac/HomeScreen/HomeScreen';
import Calendario from './pages/LIFTrac/Calendario/calendario';

const App = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.post('http://localhost:5000/api/auth/verify-token', { token })
                .then(res => {
                    setUser(res.data.user);
                })
                .catch(() => {
                    localStorage.removeItem('token');
                    setUser(null);
                });
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/sobre-nos" element={<SobreNos />} />
                <Route path="/sobre-nos/declaracao" element={<Declaracao />} />
                <Route path="/sobre-nos/historia" element={<Historia />} />
                <Route path="/sobre-nos/lideranca" element={<Lideranca />} />
                <Route path="/ministerios" element={<Ministerios />} />
                <Route path="/eventos" element={<Eventos />} />
                <Route path="/contactos" element={<Contactos />} />

                <Route path="/area-crente/login" element={user ? <Navigate to="/area-crente/home" /> : <Login setUser={setUser} />} />
                <Route path="/area-crente/home" element={user ? <HomeScreen user={user} onLogout={handleLogout} /> : <Navigate to="/area-crente/login" />} />
                <Route path="/area-crente/list" element={user ? <CrenteList user={user} onLogout={handleLogout} /> : <Navigate to="/area-crente/login" />} />
                <Route path="/area-crente/calendario" element={user ? <Calendario user={user} onLogout={handleLogout} /> : <Navigate to="/area-crente/login" />} />
            </Routes>
        </Router>
    );
};

export default App;
