/* src/pages/Site/Ministerios/Ministerios.js */
import React from "react";

import Menu from '../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../components/Site/Footer/Footer';

import styles from './Ministerios.module.css';

const Ministerios = () => {
    const ministerios = [
        {
            nome: 'LIFT4KIDS',
            subtitulo: 'Ministério Infantil',
            descricao: 'A brincar, mas sempre a aprender a conhecer Jesus\n\nInfantado: Domingos - 11h30 (1º Domingo 9h30)\n\nFanhões: Domingos - 9h30',
            imagem: 'lift4kids.png',
        },
        {
            nome: 'LIFTeens',
            subtitulo: 'Ministério de Adolescentes (13-17)',
            descricao: 'Infantado: Reuniões 2º e 4º Sábados do mês - 16h30',
            imagem: 'lifteens.png',
        },
        {
            nome: '100 Limites',
            subtitulo: 'Ministério de Jovens (18-30)',
            descricao: 'Infantado: Reuniões - 18h',
            imagem: 'jovens.png',
        },
        {
            nome: 'Yanis',
            subtitulo: 'Ministério para Mulheres',
            descricao: 'Yanis no hebraico significa “presente de Deus”',
            imagem: 'yanis.png',
        },
        {
            nome: 'ActionMan',
            subtitulo: 'Ministério para homens',
            descricao: 'Não desperdiçamos tempo a discutir como o homem bom deverá ser. Empenhamo-nos em sê-lo.\n\nReuniões na 3ª Segunda do mês às 21h.',
            imagem: 'homens.png',
        },
        {
            nome: '60+',
            subtitulo: 'Ministério da terceira idade',
            descricao: 'A experiência em alegre existência',
            imagem: '60+.png',
        },
        {
            nome: '+Juntos',
            subtitulo: 'Ministérios para casais',
            descricao: 'Um homem sozinho pode ser vencido, mas dois conseguem defender- se. Um cordão de três dobras não se rompe com facilidade.',
            imagem: 'casais.png',
        },
        {
            nome: 'LIFTart',
            subtitulo: 'Escola de Música',
            descricao: 'A arte diz o indizível; exprime o inexprimível, traduz o intraduzível.',
            imagem: 'liftart.png',
        },
    ];

    return (
        <>
            <Menu />
            <main className={styles.main}>
                <h1 className={styles.title}>Os Nossos Ministérios</h1>
                <div className={styles.mosaico}>
                    {ministerios.map((ministerio, index) => (
                        <div key={index} className={styles.ministerio}>
                            <div className={styles.imagemWrapper}>
                                <img src={`/assets/Logos/${ministerio.imagem}`} alt={ministerio.nome} className={styles.imagem} />
                                <div className={styles.overlay}>
                                    <h2 className={styles.nome}>{ministerio.nome}</h2>
                                    <h3 className={styles.subtitulo}>{ministerio.subtitulo}</h3>
                                    {ministerio.descricao.split('\n\n').map((paragrafo, i) => (
                                        <p key={i} className={styles.descricao}>{paragrafo}</p>
                                    ))}
                                </div>
                            </div>
                        </div>

                    ))}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Ministerios;
