/* src/pages/Site/Contactos/Contactos.js */
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';

import Menu from '../../../components/Site/Hamburguer/Hamburguer';
import Footer from '../../../components/Site/Footer/Footer';
import Forms from '../../../components/Site/Forms/Form';

import styles from './Contactos.module.css';

const Contactos = () => {
    const [selectedLocation, setSelectedLocation] = useState('infantado');

    const handleLocationChange = (location) => {
        setSelectedLocation(location);
    };

    const mapSrc = {
        infantado: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d440.1422944902142!2d-9.160888775517742!3d38.84347741681427!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd192d8da1121ba7%3A0xb21e165280589b8b!2sLIFTad!5e1!3m2!1spt-PT!2spt!4v1723586263420!5m2!1spt-PT!2spt",
        fanhoes: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d250.47835956906778!2d-9.155912617842644!3d38.88211836771683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd192b7cbfb401cd%3A0x4007e7ab42be13db!2sMonte%20Esperan%C3%A7a%20Instituto%20B%C3%ADblico%20das%20Assembleias%20de%20Deus%20em%20Portugal%20(MEIBAD)%20Entrada%20principal!5e1!3m2!1spt-PT!2spt!4v1723584634107!5m2!1spt-PT!2spt",
        tojal: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d501.11004577279425!2d-9.127002699405672!3d38.86036628434993!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd192c6a879609a5%3A0x649186508677740b!2sAssembleia%20de%20Deus%20do%20Tojal!5e1!3m2!1spt-PT!2spt!4v1723584887894!5m2!1spt-PT!2spt"
    };

    return (
        <>
            <Menu />
            <main>
                <section className={styles.contactoConnosco}>
                    <div className={styles.ladoEsquerdo}>
                        <div className={styles.textoContactos}>
                            <h3>Entre em contacto connosco</h3>
                            <p>
                                Se tiver alguma dúvida ou comentário, use o formulário de contacto.
                            </p>
                            <p>
                                Poderá, também, falar connosco por e-mail ou visitar uma das nossas congregações.
                            </p>
                            <h2>E-mail</h2>
                            <a href="mailto:geral@liftad.pt">
                                <p>geral@liftad.pt</p>
                            </a>
                            <h2>Visita-nos</h2>
                            <p>- R. das Lezírias 20, 2670-513 Loures, Infantado (Sede)</p>
                            <p>- Monte Esperança, Rua Alfredo Caldeira, 2670-693 Fanhões</p>
                            <p>- Rua Arquitecto Dias Coelho 12, São Julião do Tojal</p>
                        </div>
                        <div className={styles.MapaLiftad}>
                            <iframe
                                src={mapSrc[selectedLocation]}
                                width="100%"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                                title="Mapa LIFTad"
                            ></iframe>
                        </div>
                        <div className={styles.locationButtons}>
                            <button onClick={() => handleLocationChange('infantado')} className={styles.locationButtonInfantado}>
                                Infantado
                            </button>
                            <button onClick={() => handleLocationChange('fanhoes')} className={styles.locationButtonFanhoes}>
                                Fanhões
                            </button>
                            <button onClick={() => handleLocationChange('tojal')} className={styles.locationButtonTojal}>
                                Tojal
                            </button>
                        </div>
                        <div className={styles.logosContactos}>
                            <a href="https://www.instagram.com/liftad_church/" className={styles.instagramContactos}>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="https://www.facebook.com/adlift.church" className={styles.facebookContactos}>
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                            <a href="https://www.youtube.com/@lift8472" className={styles.youtubeContactos}>
                                <FontAwesomeIcon icon={faYoutube} />
                            </a>
                        </div>
                    </div>
                    <div className={styles.ladoDireito}>
                        <Forms />
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Contactos;
